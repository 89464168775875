export const coverLetterBeethoven = {
  uuid: 'IofB8XUatgrzAzuL',
  coverLetter: {
    basics: {
      jobTitle: 'Pianist',
      name: 'Ludwig van Beethoven',
    },
    contacts: {
      email: 'mozart@jobsboard,io',
      phone: '+(01) 23456789',
      linkedin: 'https://www.linkedin.com/in/amadeusmozartwolfgang/',
      city: 'London',
      country: 'United Kingdom',
    },
    company: {
      name: 'AWS',
      country: '',
    },
    content: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Dear Hiring Manager,',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'I am writing to express my keen interest in the Lead Composer position at National Opera London. With a deep passion for creating emotive and captivating musical compositions, I am excited about the opportunity to contribute to the rich artistic tradition of the National Opera.',
            bold: true,
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'As a seasoned composer with a proven track record of delivering exceptional musical works, I am confident in my ability to bring a unique and innovative approach to the role of Lead Composer. My extensive experience in composing for a variety of genres and mediums, including opera, orchestral arrangements, and film scores, has equipped me with a versatile skill set that aligns with the diverse repertoire of the National Opera.',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'I am dedicated to collaborating closely with directors, conductors, and performers to ensure that my compositions enhance and elevate the storytelling and emotional impact of the productions. My ability to interpret and convey the nuances of libretti through music, while staying true to the artistic vision of the opera, sets me apart as a composer who understands the intricacies of the genre.',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Furthermore, my proficiency in utilising modern technology and software to compose, arrange, and produce music allows me to adapt to the evolving landscape of contemporary opera while respecting and drawing inspiration from the rich heritage of the art form.',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'I am enthusiastic about the possibility of contributing to the renowned legacy of the National Opera London and am eager to discuss how my skills and experiences align with the vision of the organisation. Thank you for considering my application. I am looking forward to the opportunity to further discuss how I can contribute to the continued success of the National Opera.',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Yours sincerely,',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Ludwig van Beethoven',
          },
        ],
      },
    ],
    title: 'Cover Letter',
  },
  design: {
    templateName: 'TAURUS',
    colorPalette: 'DEFAULT',
    density: 'NORMAL',
    fontFamily: 'TIMES',
  },
  isLoading: false,
  isDirty: 1,
  isSaving: false,
};
