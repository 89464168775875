export const coverLetterStateMozard = {
  uuid: 'IofB8XUatgrzAzuL',
  coverLetter: {
    basics: {
      jobTitle: 'Composer and Musician',
      name: 'Amadeus Mozart Wolfgang',
    },
    contacts: {
      email: 'mozart@jobsboard,io',
      phone: '+(01) 23456789',
      linkedin: 'https://www.linkedin.com/in/amadeusmozartwolfgang/',
      city: 'London',
      country: 'United Kingdom',
    },
    company: {
      name: 'AWS',
      country: '',
    },
    content: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Dear Hiring Manager,',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'I am writing to express my strong interest in the Lead Composer position at National Opera London. With a passion for creating emotive and captivating musical compositions, I am excited about the opportunity to contribute to the artistic vision of such a prestigious institution.',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'As a seasoned composer with a proven track record of creating original scores for a diverse range of projects, I am confident in my ability to bring a unique and innovative approach to the National Opera London. With a deep understanding of musical theory, orchestration, and a keen eye for storytelling through music, I am dedicated to delivering compositions that resonate with audiences and elevate the overall artistic experience.',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: "I am particularly drawn to the National Opera London's commitment to pushing artistic boundaries and fostering creativity. I am eager to contribute my expertise to the company's mission of delivering exceptional performances that leave a lasting impact on audiences and the wider artistic community.",
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'I am confident that my passion for music, coupled with my strong technical skills and collaborative approach, make me a perfect fit for the Lead Composer role at National Opera London. I am eager to bring my unique perspective and creative energy to your esteemed institution and contribute to its continued success.',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Thank you for considering my application. I am looking forward to the opportunity to discuss how my background, skills, and artistic vision align with the goals of National Opera London.',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Yours sincerely,',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Amadeus Mozart Wolfgang',
          },
        ],
      },
    ],
    title: 'Cover Letter',
  },
  design: {
    templateName: 'ARIES',
    colorPalette: 'LIGHT1',
    density: 'NORMAL',
    fontFamily: 'TIMES',
  },
  isLoading: false,
  isDirty: 0,
  isSaving: false,
};
